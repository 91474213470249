<template>
    <div class="ubicacion">
        <!-- alerta CRUD -->
        <v-snackbar v-model="alerta" :color="colorAlerta" right top :timeout="timeout">
            {{ textoAlerta }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="alerta = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <!-- fin alerta CRUD -->
        <v-card>
            <v-tabs v-model="tab" align-with-title>
                <v-tabs-slider></v-tabs-slider>
                <v-tab>
                    Empresa
                </v-tab>
                <v-tab>
                    Sede
                </v-tab>
                <v-tab>
                    Bodega
                </v-tab>
            </v-tabs>
            <v-container>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card>
                            <v-dialog v-model="modalEmpresa" max-width="500px" persistent>
                                <v-card>
                                    <v-toolbar color="primary" dark>
                                        <v-card-title>
                                            <span class="text-h5">{{ tituloEmpresa }}</span>
                                        </v-card-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-container>
                                            <v-form ref="frmEmpresa" v-model="validoEmpresa" lazy-validation>
                                                <v-text-field v-model="editEmpresa.nombre" :rules="reglaNombreEmpresa"
                                                    label="Name" outlined clearable required>
                                                </v-text-field>
                                                <v-divider></v-divider>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="error" class="mr-4" @click="cancelarEmpresa">
                                                        Cancelar
                                                    </v-btn>
                                                    <v-btn :disabled="!validoEmpresa" color="success" class="mr-4"
                                                        @click="guardarEmpresa">
                                                        Guardar
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-form>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="modalEliminarEmpresa" max-width="550px">
                                <v-card>
                                    <v-card-title class="text-h6 font-weight-ligth text--secondary">
                                        <v-icon x-large left color="error">
                                            mdi-alert-octagon-outline
                                        </v-icon>
                                        ¿Estas seguro que deceas eliminar este campo?
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" @click="cerrarEliminarEmpresa">
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="success" @click="confirmarElimEmpresa">
                                            Aceptar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <!-- tabla -->
                            <tabla :header="headersEmpresa" :body="empresas" tituloTabla="EMPRESAS"
                                :accionAgregar="true" :exportar="true" @recargar="listarEmpresa"
                                @agregar="agregarEmpresa" @actualizar="editarEmpresa" @eliminar="eliminarEmpresa" />
                            <!-- tabla fin -->
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card>
                            <v-dialog v-model="modalSede" transition="dialog-top-transition" max-width="800px"
                                persistent>
                                <v-card>
                                    <v-toolbar color="primary" dark>
                                        <v-card-title>
                                            <span class="text-h5">{{ tituloSede }}</span>
                                        </v-card-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-form ref="frmSede" v-model="validoSede" lazy-validation>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="6">
                                                        <v-autocomplete v-model="sedeEmpresa" :rules="reglaSlcEmpresa"
                                                            :items="empresas" item-text="nombre" item-value="Uid"
                                                            label="Empresa" outlined clearable required>
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-autocomplete @change="listarMunicipio"
                                                            v-model="municipioDepartam" :rules="reglaSlcDepartam"
                                                            :items="departamentos" item-text="nombre" item-value="Uid"
                                                            label="Departamento" outlined clearable required>
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-autocomplete v-model="sedeMunicipio"
                                                            :rules="reglaSlcMunicipio" :items="municipios"
                                                            item-text="nombre" item-value="Uid" label="Municipio"
                                                            outlined clearable required>
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field v-model="editSede.nombre" :rules="reglaNombreSede"
                                                            label="Nombre" outlined clearable required>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field v-model="editSede.direccion"
                                                            :rules="reglaDireccionSede" label="Direccion" outlined
                                                            clearable required>
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-divider class="mt-3"></v-divider>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="error" class="mr-4" @click="cancelarSede">
                                                        Cancelar
                                                    </v-btn>
                                                    <v-btn :disabled="!validoSede" color="success" class="mr-4"
                                                        @click="guardarSede">
                                                        Guardar
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-container>
                                        </v-form>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="modalEliminarSede" max-width="550px">
                                <v-card>
                                    <v-card-title class="text-h6 font-weight-ligth text--secondary">
                                        <v-icon x-large left color="error">
                                            mdi-alert-octagon-outline
                                        </v-icon>
                                        ¿Estas seguro que deceas eliminar este campo?
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" @click="cerrarEliminarSede">
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="success" @click="confirmarElimSede">
                                            Aceptar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <!-- tabla -->
                            <tabla :header="headersSede" :body="sedes" tituloTabla="EMPRESAS" :accionAgregar="true"
                                :exportar="true" @recargar="listarSede" @agregar="agregarSede" @actualizar="editarSede"
                                @eliminar="eliminarSede" />
                            <!-- tabla fin -->
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card>
                            <v-dialog v-model="modalBodega" max-width="800px" persistent>
                                <v-card>
                                    <v-toolbar color="primary" dark>
                                        <v-card-title>
                                            <span class="text-h5">{{ tituloBodega }}</span>
                                        </v-card-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-container>
                                            <v-form ref="frmBodega" v-model="validoBodega" lazy-validation>
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12" md="6">
                                                            <v-autocomplete v-model="bodegaUsuario"
                                                                :rules="reglaSlcUsuaurio" :items="usuarios"
                                                                item-text="nombre" item-value="Uid"
                                                                label="Persona a cargo" outlined clearable required>
                                                            </v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <v-autocomplete v-model="bodegaSede" :rules="reglaSlcSede"
                                                                :items="sedes" item-text="nombre" item-value="Uid"
                                                                label="Sede" outlined clearable required>
                                                            </v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-text-field v-model="editBodega.nombre"
                                                                :rules="reglaNombreBodega" label="Name" outlined
                                                                clearable required>
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-divider></v-divider>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="error" class="mr-4" @click="cancelarBodega">
                                                            Cancelar
                                                        </v-btn>
                                                        <v-btn :disabled="!validoBodega" color="success" class="mr-4"
                                                            @click="guardarBodega">
                                                            Guardar
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-container>
                                            </v-form>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="modalEliminarBodega" max-width="550px">
                                <v-card>
                                    <v-card-title class="text-h6 font-weight-ligth text--secondary">
                                        <v-icon x-large left color="error">
                                            mdi-alert-octagon-outline
                                        </v-icon>
                                        ¿Estas seguro que deceas eliminar este campo?
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" @click="cerrarEliminarBodega">
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="success" @click="confirmarElimBodega">
                                            Aceptar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <!-- tabla -->
                            <tabla :header="headersBodega" :body="bodegas" tituloTabla="BODEGA" :accionAgregar="true"
                                :exportar="true" @recargar="listarBodega" @agregar="agregarBodega"
                                @actualizar="editarBodega" @eliminar="eliminarBodega" />
                            <!-- tabla fin -->
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-container>
        </v-card>
    </div>
</template>
<style>
</style>
<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    import tabla from "../components/view/Tabla";
    export default {
        name: "empresa",
        components: {
            tabla,
        },
        data: () => ({
            url_api: process.env.VUE_APP_URL_API,
            tab: null,
            municipios: [],

            alerta: false,
            textoAlerta: '',
            colorAlerta: '',
            timeout: 2000,
            // variables empresa 
            UidEmpresa: null,
            buscarEmpresa: '',
            validoEmpresa: true,
            modalEmpresa: false,
            modalEliminarEmpresa: false,
            headersEmpresa: [{
                    text: '#',
                    value: 'numero'
                },
                {
                    text: 'Nombre / País',
                    align: 'start',
                    value: 'nombre',
                },

                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            empresas: [],
            reglaNombreEmpresa: [
                v => !!v || 'El nombre de la empresa es obligatorio.'
            ],
            editedEmpresaIndex: -1,
            editEmpresa: {
                nombre: ''
            },
            defaultEmpresa: {
                nombre: ''
            },
            // fin variables empresa

            //variables sede
            UidSede: null,
            buscarSede: '',
            validoSede: true,
            modalSede: false,
            modalEliminarSede: false,
            headersSede: [{
                    text: '#',
                    value: 'numero'
                },
                {
                    text: 'Nombre / Sede',
                    align: 'start',
                    value: 'nombre',
                },
                {
                    text: 'Municipio',
                    align: 'start',
                    value: 'municipio',
                },
                {
                    text: 'Empesa',
                    align: 'start',
                    value: 'empresa',
                },
                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            sedes: [],
            reglaNombreSede: [
                v => !!v || 'El nombre de la sede es obligatorio.'
            ],
            reglaDireccionSede: [
                v => !!v || 'La direccion de la sede es obligatorio.'
            ],
            reglaSlcEmpresa: [v => !!v || 'La empresa es obligatoria'],
            reglaSlcDepartam: [v => !!v || 'El departamento es obligatorio'],
            reglaSlcMunicipio: [v => !!v || 'El municipio es obligatoria'],
            editedSedeIndex: -1,
            sedeEmpresa: null,
            sedeMunicipio: null,
            editSede: {
                nombre: '',
                direccion: '',
            },
            defaultSede: {
                nombre: '',
                direccion: '',
            },
            // fin variables sede
            // variables bodega 
            UidBodega: null,
            municipioDepartam: null,
            buscarBodega: '',
            validoBodega: true,
            modalBodega: false,
            modalEliminarBodega: false,
            headersBodega: [{
                    text: '#',
                    value: 'numero'
                },
                {
                    text: 'Nombre / Bodega',
                    align: 'start',
                    value: 'nombre',
                },
                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            bodegas: [],
            usuarios: [],
            departamentos: [],
            reglaNombreBodega: [
                v => !!v || 'El nombre de la bodega es obligatorio.'
            ],
            reglaSlcSede: [v => !!v || 'La empresa es obligatoria'],
            reglaSlcUsuaurio: [v => !!v || 'El usuario es obligatoria'],
            editedBodegaIndex: -1,
            bodegaSede: null,
            bodegaUsuario: null,
            editBodega: {
                nombre: ''
            },
            defaultBodega: {
                nombre: ''
            },
            // fin variables bodega
        }),

        computed: {
            ...mapState(["token"]),
            // titulo modal empresa 
            tituloEmpresa() {
                return this.editedEmpresaIndex === -1 ? 'Nuevo Empresa' : 'Editar Empresa'
            },
            // titulo modal sede 
            tituloSede() {
                return this.editedSedeIndex === -1 ? 'Nuevo Sede' : 'Editar Sede'
            },
            // titulo modal bodega 
            tituloBodega() {
                return this.editedBodegaIndex === -1 ? 'Nuevo Bodega' : 'Editar Bodega'
            },
        },

        watch: {
            // modal empresa
            modalEmpresa(val) {
                val || this.cancelarEmpresa()
            },
            modalEliminarEmpresa(val) {
                val || this.cerrarEliminarEmpresa()
            },
            // fin modal empresa
            // modal sede
            modalSede(val) {
                val || this.cancelarSede()
            },
            modalEliminarSede(val) {
                val || this.cerrarEliminarSede()
            },
            // fin modal sede
            // modal bodega
            modalBodega(val) {
                val || this.cancelarBodega()
            },
            modalEliminarBodega(val) {
                val || this.cerrarEliminarBodega()
            },
            // fin modal bodega
        },

        created() {
            // listar empresas
            this.listarEmpresa();
            // listar sedes
            this.listarSede();
            // listar bodegas
            this.listarBodega();
            // listar departamentos
            this.listarDepartam();
            // listar usuarios
            this.listarUsuario();
        },

        methods: {
            ...mapActions(['cerrarSesion']),

            // CRUD paises 
            async listarEmpresa() {
                try {
                    await fetch(`${this.url_api}/empresa`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.empresas = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            agregarEmpresa() {
                this.modalEmpresa = true
            },

            editarEmpresa(empresa) {
                this.editedEmpresaIndex = this.empresas.indexOf(empresa)
                this.editEmpresa = Object.assign({}, empresa)
                this.modalEmpresa = true
                this.UidEmpresa = empresa.Uid;
            },

            eliminarEmpresa(empresa) {
                this.editedEmpresaIndex = this.empresas.indexOf(empresa)
                this.editEmpresa = Object.assign({}, empresa)
                this.modalEliminarEmpresa = true
                this.UidEmpresa = empresa.Uid;
            },

            async confirmarElimEmpresa() {
                try {
                    await fetch(`${this.url_api}/empresa/${this.UidEmpresa}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato eliminado con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.empresas.splice(this.editedEmpresaIndex, 1);
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Error al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
                this.cerrarEliminarEmpresa();
            },

            cancelarEmpresa() {
                this.$refs.frmEmpresa.reset()
                this.$refs.frmEmpresa.resetValidation()
                this.modalEmpresa = false
                this.$nextTick(() => {
                    this.editEmpresa = Object.assign({}, this.defaultEmpresa)
                    this.editedEmpresaIndex = -1
                })
            },

            cerrarEliminarEmpresa() {
                this.modalEliminarEmpresa = false
                this.$nextTick(() => {
                    this.editEmpresa = Object.assign({}, this.defaultEmpresa)
                    this.editedEmpresaIndex = -1
                })
            },

            async guardarEmpresa() {
                let me = this;
                if (this.$refs.frmEmpresa.validate()) {
                    if (this.editedEmpresaIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/empresa/${this.UidEmpresa}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify({
                                        "nombreEmpresa": this.editEmpresa.nombre
                                    })
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato acrualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.empresas[this.editedEmpresaIndex], this
                                            .editEmpresa);
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        me.UidEmpresa = null;
                    } else {
                        try {
                            await fetch(`${this.url_api}/empresa`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify({
                                        "nombreEmpresa": this.editEmpresa.nombre
                                    })
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato agregado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarEmpresa();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al agregar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                    this.cancelarEmpresa();
                }
            },
            // fin CRUD empresas

            // CRUD sedes 
            async listarSede() {
                try {
                    await fetch(`${this.url_api}/sede`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.sedes = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            agregarSede() {
                this.modalSede = true;
            },

            editarSede(sede) {
                let me = this;
                this.empresas.forEach(element => {
                    if (element.Uid == sede.Uid_empresa) {
                        me.sedeEmpresa = element.Uid
                    }
                });
                this.municipioDepartam = sede.UidDepartamento;
                this.listarMunicipio(sede.UidMunicipio);


                this.editedSedeIndex = this.sedes.indexOf(sede)
                this.editSede = Object.assign({}, sede)
                this.modalSede = true
                this.UidSede = sede.Uid;

            },

            eliminarSede(sede) {
                this.editedSedeIndex = this.sedes.indexOf(sede)
                this.editSede = Object.assign({}, sede)
                this.modalEliminarSede = true
                this.UidSede = sede.Uid;
            },

            async confirmarElimSede() {
                try {
                    await fetch(`${this.url_api}/sede/${this.UidSede}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato eliminado con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.sedes.splice(this.editedSedeIndex, 1);
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Error al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
                this.cerrarEliminarSede();
            },

            cancelarSede() {
                this.$refs.frmSede.reset()
                this.$refs.frmSede.resetValidation()
                this.modalSede = false
                this.$nextTick(() => {
                    this.editSede = Object.assign({}, this.defaultSede)
                    this.editedSedeIndex = -1
                })
            },

            cerrarEliminarSede() {
                this.modalEliminarSede = false
                this.$nextTick(() => {
                    this.editSede = Object.assign({}, this.defaultSede)
                    this.editedSedeIndex = -1
                })
            },

            async guardarSede() {
                let me = this;
                if (this.$refs.frmSede.validate()) {
                    const data = {
                        "municipioSede": me.sedeMunicipio,
                        "empresaSede": me.sedeEmpresa,
                        "nombreSede": me.editSede.nombre,
                        "direccionSede": me.editSede.direccion
                    }
                    if (this.editedSedeIndex > -1) {

                        try {
                            await fetch(`${this.url_api}/sede/${this.UidSede}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(data)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato acrualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.sedes[this.editedSedeIndex], this.editSede);
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        this.Uid = null;
                    } else {
                        try {
                            await fetch(`${this.url_api}/sede`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(data)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato agregado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarSede();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al agregar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                    this.cancelarSede();
                }
            },
            // fin CRUD sedes
            // CRUD bodegas 
            async listarBodega() {
                try {
                    await fetch(`${this.url_api}/bodega`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.bodegas = response.body;
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            async listarUsuario() {
                try {
                    await fetch(`${this.url_api}/persona`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.usuarios = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            agregarBodega() {
                this.modalBodega = true
            },

            editarBodega(bodega) {
                let me = this;
                this.sedes.forEach(element => {
                    if (element.Uid == bodega.sede) {
                        me.bodegaSede = element.Uid;
                    }
                });

                this.usuarios.forEach(element => {
                    if (element.Uid == bodega.usuario) {
                        me.bodegaUsuario = element.Uid;
                    }
                });
                this.editedBodegaIndex = this.bodegas.indexOf(bodega)
                this.editBodega = Object.assign({}, bodega)
                this.modalBodega = true
                this.UidBodega = bodega.Uid;

            },

            eliminarBodega(bodega) {
                this.editedBodegaIndex = this.bodegas.indexOf(bodega)
                this.editBodega = Object.assign({}, bodega)
                this.modalEliminarBodega = true
                this.UidBodega = bodega.Uid;
            },

            async confirmarElimBodega() {
                try {
                    await fetch(`${this.url_api}/bodega/${this.UidBodega}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato eliminado con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.bodegas.splice(this.editedBodegaIndex, 1);
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Error al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
                this.cerrarEliminarBodega();
            },

            cancelarBodega() {
                this.$refs.frmBodega.reset()
                this.$refs.frmBodega.resetValidation()
                this.modalBodega = false
                this.$nextTick(() => {
                    this.editBodega = Object.assign({}, this.defaultBodega)
                    this.editedBodegaIndex = -1
                })
            },

            cerrarEliminarBodega() {
                this.modalEliminarBodega = false
                this.$nextTick(() => {
                    this.editBodega = Object.assign({}, this.defaultBodega)
                    this.editedBodegaIndex = -1
                })
            },

            async guardarBodega() {
                let me = this;
                if (this.$refs.frmBodega.validate()) {
                    if (this.editedBodegaIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/bodega/${this.UidBodega}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify({
                                        "sedeBodega": me.bodegaSede,
                                        "usuarioBodega": me.bodegaUsuario,
                                        "nombreBodega": me.editBodega.nombre
                                    })
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato actualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.bodegas[this.editedBodegaIndex], this
                                            .editBodega);
                                        this.listarBodega();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        this.UidBodega = null;
                    } else {
                        try {
                            await fetch(`${this.url_api}/bodega`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify({
                                        "sedeBodega": me.bodegaSede,
                                        "usuarioBodega": me.bodegaUsuario,
                                        "nombreBodega": me.editBodega.nombre
                                    })
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato agregado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarBodega();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al agregar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                    this.cancelarBodega();

                }
            },
            // fin CRUD bodegas

            // CRUD departamentos 
            async listarDepartam() {
                try {
                    await fetch(`${this.url_api}/departamento`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.departamentos = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            async listarMunicipio(a) {
                this.municipios = [];
                try {
                    await fetch(`${this.url_api}/municipio`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.municipios = response.body.filter(element =>
                                    element.departamento ==
                                    this.municipioDepartam)
                                this.municipios.forEach(element => {
                                    if (element.Uid == a) {
                                        this.sedeMunicipio = element.Uid;
                                    }
                                });
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },
        },
    }
</script>